import NavBarItem from "./NavBarItem"
import { getSearchUrl, getApiDocsUrl } from "../utils/helpers"

export default function NavBar() {
  return (
    <>
      <ul className="nav flex-column">
        <NavBarItem to="/" name="My Account" />
        <NavBarItem to="/subscribe" name="Subscriptions" />
        <NavBarItem href={getSearchUrl()} name="Dataset Search" />
        <NavBarItem to="/history" name="Search history" />
        <NavBarItem href={getApiDocsUrl()} name="API Docs" />
        <NavBarItem to="/feedback" name="Share Feedback" />
      </ul>

      <hr className="my-3" />

      <ul className="nav flex-column mb-auto">
        <NavBarItem to="/settings" name="Advanced settings" />
        <NavBarItem to="/account/logout" name="Sign out" />
      </ul>
    </>
  )
}
