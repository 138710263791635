import { useState, useEffect } from "react"
import { AuthChangeRedirector, AnonymousRoute, AuthenticatedRoute } from "./auth"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Settings from "./account/AccountSettings"
import Login from "./account/Login"
import RequestLoginCode from "./account/RequestLoginCode"
import ConfirmLoginCode from "./account/ConfirmLoginCode"
import Logout from "./account/Logout"
import Signup from "./account/Signup"
import ProviderSignup from "./socialaccount/ProviderSignup"
import ProviderCallback from "./socialaccount/ProviderCallback"
import Home from "./Home"
import ChangeEmail from "./account/ChangeEmail"
import ManageProviders from "./socialaccount/ManageProviders"
import VerifyEmail, { loader as verifyEmailLoader } from "./account/VerifyEmail"
import VerificationEmailSent from "./account/VerificationEmailSent"
import RequestPasswordReset from "./account/RequestPasswordReset"
import ChangePassword from "./account/ChangePassword"
import ResetPassword, { loader as resetPasswordLoader } from "./account/ResetPassword"
import Reauthenticate from "./account/Reauthenticate"
import Sessions from "./usersessions/Sessions"
import Root from "./Root"
import Checkout from "./checkout/index"
import Subscribe from "./subscriptions/index"
import SearchHistory from "./search/SearchHistory"
import ErrorBoundary from "./components/ErrorBoundary"
import SetTitle from "./hooks/SetTitle"

function createRouter() {
  return createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthChangeRedirector>
          <Root />
        </AuthChangeRedirector>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "/",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Welcome" fnTitle={(username) => `Welcome, ${username}`}>
                <Home />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/feedback",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Share Feedback">
                <Checkout />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/settings",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Account Settings">
                <Settings />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/subscribe",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Subscriptions">
                <Subscribe />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/history",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="My Search History">
                <SearchHistory />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/account/login",
          element: (
            <AnonymousRoute>
              <SetTitle title="Sign In">
                <Login />
              </SetTitle>
            </AnonymousRoute>
          ),
        },
        {
          path: "/account/login/code",
          element: (
            <AnonymousRoute>
              <SetTitle title="Request Login Code">
                <RequestLoginCode />
              </SetTitle>
            </AnonymousRoute>
          ),
        },
        {
          path: "/account/login/code/confirm",
          element: (
            <AnonymousRoute>
              <SetTitle title="Confirm Login Code">
                <ConfirmLoginCode />
              </SetTitle>
            </AnonymousRoute>
          ),
        },
        {
          path: "/account/email",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Manage Connected Emails">
                <ChangeEmail />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/account/logout",
          element: (
            <SetTitle title="Sign Out">
              <Logout />
            </SetTitle>
          ),
        },
        {
          path: "/account/provider/callback",
          element: (
            <SetTitle title="Provider Callback">
              <ProviderCallback />
            </SetTitle>
          ),
        },
        {
          path: "/account/provider/signup",
          element: (
            <AnonymousRoute>
              <SetTitle title="One More Step">
                <ProviderSignup title="Welcome" />
              </SetTitle>
            </AnonymousRoute>
          ),
        },
        {
          path: "/account/providers",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Manage Providers">
                <ManageProviders />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/account/signup",
          element: (
            <AnonymousRoute>
              <SetTitle title="Sign Up">
                <Signup />
              </SetTitle>
            </AnonymousRoute>
          ),
        },
        {
          path: "/account/verify-email",
          element: (
            <SetTitle title="Verification Email Sent">
              <VerificationEmailSent />
            </SetTitle>
          ),
        },
        {
          path: "/account/verify-email/:key",
          element: (
            <SetTitle title="Verify Email Address">
              <VerifyEmail />
            </SetTitle>
          ),
          loader: verifyEmailLoader,
        },
        {
          path: "/account/password/reset",
          element: (
            <AnonymousRoute>
              <RequestPasswordReset />
            </AnonymousRoute>
          ),
        },
        {
          path: "/account/password/reset/key/:key",
          element: (
            <AnonymousRoute>
              <ResetPassword />
            </AnonymousRoute>
          ),
          loader: resetPasswordLoader,
        },
        {
          path: "/account/password/change",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Change Password">
                <ChangePassword />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/account/reauthenticate",
          element: (
            <AuthenticatedRoute>
              <Reauthenticate />
            </AuthenticatedRoute>
          ),
        },
        {
          path: "/account/sessions",
          element: (
            <AuthenticatedRoute>
              <SetTitle title="Your sessions at Dateno">
                <Sessions />
              </SetTitle>
            </AuthenticatedRoute>
          ),
        },
      ],
    },
  ])
}

export default function Router() {
  // If we create the router globally, the loaders of the routes already trigger
  // even before the <AuthContext/> trigger the initial loading of the auth.
  // state.
  const [router, setRouter] = useState(null)
  useEffect(() => {
    setRouter(createRouter())
  }, [])
  return router ? <RouterProvider router={router} /> : null
}
