import { useState } from "react"
import { getSocialUrl } from "../utils/helpers"
import { sendUsersFeedback } from "../utils/restapi"

const SocLink = ({ app }) => {
  return (
    <a
      title={app}
      href={getSocialUrl(app)}
      target="_blank"
      rel="noreferrer"
      className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
    >
      {app}
    </a>
  )
}

const FeedbackSuccess = () => {
  return (
    <div className="alert alert-success mt-3" role="alert">
      <p>Thank you for sharing!</p>
      <p className="text-secondary my-3 small">
        Stay connected and keep in the loop with all the latest news! Follow us for updates on{" "}
        <SocLink app="Telegram" />, <SocLink app="Medium" />, <SocLink app="Discord" />, <SocLink app="Twitter" />, and{" "}
        <SocLink app="GitHub" />.
      </p>
    </div>
  )
}

const FeedbackError = () => {
  return (
    <div className="alert alert-error mt-3" role="alert">
      Sorry. Something didn't go right.
    </div>
  )
}

const Feedback = () => {
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [feedbackFailed, setFeedbackFailed] = useState(false)
  const [message, setMessage] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()

    const feedbackData = {
      message,
    }

    try {
      await sendUsersFeedback(feedbackData)
      setFeedbackSent(true)
      setFeedbackFailed(false)
      setMessage("")
    } catch (error) {
      setFeedbackSent(false)
      setFeedbackFailed(true)
    }
  }

  const betaMessage = "Include me as a beta tester."
  const handleBetaClick = () => {
    setMessage(message + "\n" + betaMessage)
    setFeedbackSent(false)
    setFeedbackFailed(false)
    return false
  }
  const handleMessageChange = (e) => {
    setMessage(e.target.value)
    setFeedbackSent(false)
    setFeedbackFailed(false)
  }
  return (
    <div className="px-2">
      <div className="">
        {feedbackSent ? <FeedbackSuccess /> : null}
        {feedbackFailed ? <FeedbackError /> : null}
        <p className="m-0 py-2">
          Got feedback for us? You can leave it right here. If you've noticed any issues or if there's a feature you’re
          hoping to see, let us know. We’re always eager to improve and appreciate your input.
        </p>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            onChange={handleMessageChange}
            style={{ height: "8rem" }}
            value={message}
          />
        </div>
        <p className="py-2 m-0 mt-2">
          <a
            title="Click to add"
            href="#beta"
            className="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover"
            onClick={handleBetaClick}
          >
            {betaMessage}
          </a>
        </p>
      </div>
      <div className="py-1">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={!message}
        >
          Send Feedback
        </button>
      </div>
    </div>
  )
}

export default Feedback
