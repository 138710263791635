import Feedback from "./Feedback"
import MainWrap from "../components/MainWrap"
import { useUser } from "../auth"

export default function Checkout() {
  const user = useUser()
  return (
    <MainWrap NoticePanel={<p className="text-body-tertiary fs-08 m-0 px-1 py-1">{user.email}</p>}>
      <Feedback />
    </MainWrap>
  )
}
